import React from 'react';
import { GiGymBag } from "react-icons/gi";
import { GiBuyCard } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { FaTaxi } from "react-icons/fa";
import { TbSocial } from "react-icons/tb";
import { FaPhotoVideo } from "react-icons/fa";
import Lottie from 'react-lottie';
import PersonalProjects from '../Lottie/projectIdea.json';


const Projects = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: PersonalProjects,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='h-auto float-left overflow-auto px-4 lg:px-28'>
            <div className='overflow-auto my-4'>
                <div className='text-4xl sm:text-3xl font-bold flex items-center justify-start'>
                    Projects
                    <span className='w-min'>
                        <Lottie options={defaultOptions} height={50} width={50} />
                    </span>
                </div>
            </div>

            {/* Projects */}
            <div className='w-[100%] h-auto py-4 float-left rounded-lg border-[0.025rem] border-gray-light shadow-lg shadow-white-input-light my-6'>
                <div className='w-[100%] px-[2%] h-auto flex flex-wrap items-center justify-start sm:justify-center'>

                    <div className='w-[44%] sm:w-[98%] mx-[3%] sm:mx-[1%] my-4 h-[5rem] overflow-hidden'>
                        <a className='flex items-center justify-start' href='https://github.com/Shreyash019/Video-Thumbnail-Creation' target='_blank' rel='noreferrer'>
                            <div className='w-[20%] h-[5rem] float-left flex items-center justify-center text-5xl text-project-icon'><FaPhotoVideo /></div>
                            <div className='w-[78%] pl-[1%] h-[5rem] float-left flex items-center justify-center'>
                                <div className='w-full h-auto block overflow-hidden'>
                                    <p className='text-2xl sm:text-lg font-bold text-blue truncate overflow-hidden'>Video-Thumbnail-Creation</p>
                                    <p className='text-sm sm:text-xs break-word line-clamp-3'><i>Node.js, Express, JavaScript, MongoDD, REST API, Cloudinary</i></p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className='w-[44%] sm:w-[98%] mx-[3%] sm:mx-[1%] my-4 h-[5rem] overflow-hidden'>
                        <a className='flex items-center justify-start' href='https://github.com/Shreyash019/BuySell' target='_blank' rel='noreferrer'>
                            <div className='w-[20%] h-[5rem] float-left flex items-center justify-center text-5xl text-project-icon'><GiBuyCard /></div>
                            <div className='w-[78%] pl-[1%] h-[5rem] float-left flex items-center justify-center'>
                                <div className='w-full h-auto block overflow-hidden'>
                                    <p className='text-2xl sm:text-lg font-bold text-blue truncate overflow-hidden'>Buy&Sell</p>
                                    <p className='text-sm sm:text-xs break-word line-clamp-3'><i>React Js, JavaScript, Tailwind</i></p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className='w-[44%] sm:w-[98%] mx-[3%] sm:mx-[1%] my-4 h-[5rem] overflow-hidden'>
                        <a className='flex items-center justify-start' href='https://github.com/Shreyash019/GYM-GO' target='_blank' rel='noreferrer'>
                            <div className='w-[20%] h-[5rem] float-left flex items-center justify-center text-5xl text-project-icon'><GiGymBag /></div>
                            <div className='w-[78%] pl-[1%] h-[5rem] float-left flex items-center justify-center'>
                                <div className='w-full h-auto block overflow-hidden'>
                                    <p className='text-2xl sm:text-lg font-bold text-blue truncate overflow-hidden'>GYM-GO</p>
                                    <p className='text-sm sm:text-xs break-word line-clamp-3'><i>React Js, JavaScript, CSS</i></p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className='w-[44%] sm:w-[98%] mx-[3%] sm:mx-[1%] my-4 h-[5rem] overflow-hidden'>
                        <a className='flex items-center justify-start' href='https://github.com/Shreyash019/Social' target='_blank' rel='noreferrer'>
                            <div className='w-[20%] h-[5rem] float-left flex items-center justify-center text-5xl text-project-icon'><TbSocial /></div>
                            <div className='w-[78%] pl-[1%] h-[5rem] float-left flex items-center justify-center'>
                                <div className='w-full h-auto block overflow-hidden'>
                                    <p className='text-2xl sm:text-lg font-bold text-blue truncate overflow-hidden'>Socio - Social Media</p>
                                    <p className='text-sm sm:text-xs break-word'><i>Node.js, Express, JavaScript, Rest API, Socket.io, MongoDB</i></p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className='w-[44%] sm:w-[98%] mx-[3%] sm:mx-[1%] my-4 h-[5rem] overflow-hidden'>
                        <a className='flex items-center justify-start' href='https://github.com/Shreyash019/eCommerce' target='_blank' rel='noreferrer'>
                            <div className='w-[20%] h-[5rem] float-left flex items-center justify-center text-5xl text-project-icon'><FaShoppingCart /></div>
                            <div className='w-[78%] pl-[1%] h-[5rem] float-left flex items-center justify-center'>
                                <div className='w-full h-auto block overflow-hidden'>
                                    <p className='text-2xl sm:text-lg font-bold text-blue truncate overflow-hidden'>E-commerce</p>
                                    <p className='text-sm sm:text-xs break-word line-clamp-3'><i>Node Js, Express, JavaScript, MongoDB, REST API</i></p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className='w-[44%] sm:w-[98%] mx-[3%] sm:mx-[1%] my-4 h-[5rem] overflow-hidden'>
                        <a className='flex items-center justify-start' href='https://github.com/Shreyash019/Car-Rentals' target='_blank' rel='noreferrer'>
                            <div className='w-[20%] h-[5rem] float-left flex items-center justify-center text-5xl text-project-icon'><FaTaxi /></div>
                            <div className='w-[78%] pl-[1%] h-[5rem] float-left flex items-center justify-center'>
                                <div className='w-full h-auto block overflow-hidden'>
                                    <p className='text-2xl sm:text-lg font-bold text-blue truncate overflow-hidden'>Car Rental</p>
                                    <p className='text-sm sm:text-xs break-word line-clamp-3'><i>Node Js, Express, JavaScript, MongoDB, REST API</i></p>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Projects