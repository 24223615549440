import React, { useState } from 'react';
import Introduction from './Introduction';
import MenuCard from './MenuCard';
import TechSkills from './TechSkills';
import Experience from "./Experience"
import Projects from './Projects';
import PublishedArticles from './PublishedArticles';
// import Contact from './Contact';
import Footer from './Footer';
import { TbMenu } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import Logo from "./sk.png";
import BlogPost from './Posts/BlogPost';

const WelcomeWindow = () => {
    const [open, setOpen] = useState(false);
    const scrollToTop = () => {
        setOpen(false)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <div className='w-screen min-h-screen h-auto overflow-auto bg-white'>
            <div className=''>
                <div className='w-full h-16 lg:h-20 bg-white rounded-b-md shadow-lg shadow-white-input-light flex items-center justify-center fixed z-50'>
                    <div className='w-[50%] px-8 lg:px-20' >
                        <div className='w-12 h-12 overflow-hidden rounded-full' onClick={scrollToTop}><img src={Logo} alt='Logo' /></div>
                    </div>
                    <div className='w-[50%] px-8 lg:px-20 flex items-end justify-end'>
                        {/* <p className='text-xl'>{open ? <IoClose className='rounded-full border-[0.025rem]' onClick={() => setOpen(false)} /> : <TbMenu onClick={() => setOpen(true)} />}</p> */}
                    </div>
                </div>
                {open && (
                    <div className='h-auto pt-16 lg:pt-20'>
                        <div><MenuCard /></div>
                    </div>
                )}
                {!open && (
                    <div className='w-full h-auto pt-16 lg:pt-20'>
                        <div className='w-full sm:mt-20 my-8 float-left'><Introduction /></div>
                        <div className='w-full my-4 float-left'><Experience /></div>
                        <div className='w-full my-4 float-left'><TechSkills /></div>
                        <div className='w-full my-4 float-left'><Projects /></div>
                        <div className='w-full my-4 float-left'><PublishedArticles /></div>
                        {/* <div className='w-full my-4 float-left bg-blue'><BlogPost /></div> */}
                        {/* <div><Contact /></div> */}
                        <div><Footer /></div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default WelcomeWindow