import React from 'react';
import { GiAlienSkull } from "react-icons/gi";

const Footer = () => {
    return (
        <div className='w-full h-auto float-left overflow-auto'>
            <div className='w-full float-left overflow-auto flex items-center justify-center'>
                <hr className='w-[30%] h-[0.025rem] bg-white-input-light border-none' />
                <GiAlienSkull className='text-2xl text-gray-light mx-1 animate-pulse z-10' />
                <hr className='w-[30%] h-[0.025rem] bg-white-input-light border-none' />
            </div>
            <div className='w-full h-auto float-left my-2'>
                <p className='text-center text-[0.5rem] text-gray-light'>Shreyash Kumar @2024</p>
            </div>
        </div>
    )
}

export default Footer