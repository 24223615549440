import React from 'react';
import Lottie from 'react-lottie';
import Rocket_Animation from '../Lottie/Rocket.json';



const Introduction = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Rocket_Animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='w-full px-4 lg:px-28'>
            <div className='w-min h-auto float-left text'>
                <Lottie
                    options={defaultOptions}
                    height={100}
                    width={100}
                />
            </div>
            <div className='w-full float-left overflow-auto'>
                <p className='text-2xl lg:text-4xl'>Hello 👋🏻, I'm</p>
                <p className='text-6xl lg:text-9xl font-bold text-green my-2'>Shreyash Kumar</p>
                <p className='text-2xl lg:text-4xl font-medium my-2'>
                    Innovative Software Developer Crafting Exceptional Applications
                </p>
            </div>
            <div className='w-full h-auto py-12 overflow-auto flex-wrap items-start justify-center'>
                <div className='w-8 h-8 my-2 lg:w-12 lg:h-12 mr-4 lg:mr-8 float-left overflow-hidden'>
                    <a className='flex items-center justify-start' href='https://www.linkedin.com/in/shreyash-51sk998p1/' target='_blank' rel='noreferrer'>
                        <img className='w-full' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827674/linkedIn_w0z8yk.png' alt='Linkedin' />
                    </a>
                </div>
                <div className='w-8 h-8 my-2 lg:w-12 lg:h-12 mr-4 lg:mr-8 float-left overflow-hidden'>
                    <a className='flex items-center justify-start' href='https://github.com/Shreyash019' target='_blank' rel='noreferrer'>
                        <img className='w-full' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827675/github_f1nmew.png' alt='Github' />
                    </a>
                </div>
                <div className='w-8 h-8 my-2 lg:w-12 lg:h-12 mr-4 lg:mr-8 float-left overflow-hidden'>
                    <a className='flex items-center justify-start' href='https://leetcode.com/u/devWithShreyash/' target='_blank' rel='noreferrer'>
                        <img className='w-full' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827674/LeetCode_ud78kn.png' alt='LeetCode' />
                    </a>
                </div>
                <div className='w-8 h-8 my-2 lg:w-12 lg:h-12 mr-4 lg:mr-8 float-left overflow-hidden'>
                    <a className='flex items-center justify-start' href='https://www.hackerrank.com/profile/devWithShreyash' target='_blank' rel='noreferrer'>
                        <img className='w-full' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827674/hackerrank_bl1xwp.png' alt='Hacker_Rank' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Introduction