import React from 'react';
import { CiUser } from "react-icons/ci";
import { CiLaptop } from "react-icons/ci";
import { GiAlienSkull } from "react-icons/gi";


const MenuCard = () => {
    return (
        <div className='w-full min-h-screen h-auto px-2 py-6 lg:p-10 flex items-start justify-center'>
            <div className='w-full lg:w-[50%] h-full block'>
                <div className='w-full h-auto float-left overflow-auto flex items-center justify-center py-4 mb-4'>
                    <img className='w-44 h-44 rounded-full border border-green' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827677/Shreyash_kphofr.png' alt='Shreyash' />
                </div>
                <div className='w-full h-auto float-left overflow-auto my-4'>
                    <div className='text-center text-3xl lg:text-6xl font-semibold lg:font-extrabold text-green flex items-center justify-center break-word'><CiUser />Shreyash Kumar</div>
                    <div className='text-center text-2xl lg:text-3xl text-navy flex items-center justify-center break-word my-4'><CiLaptop />&nbsp;Software Engineer</div>
                    <div className='w-full p-2'>
                        <p className='text-center text-sm lg:text-lg font-extralight'>
                            <i>📍 Driven software engineer with a deep passion for crafting clean, efficient, and maintainable code. I'm a lifelong learner, constantly seeking to expand my knowledge and explore new technologies.  I bring a positive and collaborative spirit to every project, and I'm eager to contribute to a team that shares my enthusiasm for building the future with software.</i>
                        </p>
                    </div>
                </div>
                <div className='w-full overflow-auto float-left h-12 flex items-center justify-center'>
                    <div className='w-mix px-4 flex'>
                        <div className='w-8 h-auto p-1 mx-2 overflow-hidden'>
                            <a className='flex items-center justify-start' href='https://www.linkedin.com/in/shreyash-51sk998p1/' target='_blank' rel='noreferrer'>
                                <img className='w-8 h-auto' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827674/linkedIn_w0z8yk.png' alt='LinkedIn' />
                            </a>
                        </div>
                        <div className='w-8 h-auto p-1 mx-2 overflow-hidden'>
                            <a className='flex items-center justify-start' href='https://github.com/Shreyash019' target='_blank' rel='noreferrer'>
                                <img className='w-8 h-auto' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827675/github_f1nmew.png' alt='GitHub' />
                            </a>
                        </div>
                        <div className='w-8 h-auto p-1 mx-2 overflow-hidden'>
                            <a className='flex items-center justify-start' href='https://leetcode.com/u/devWithShreyash/' target='_blank' rel='noreferrer'>
                                <img className='w-8 h-auto' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827674/LeetCode_ud78kn.png' alt='LeetCode' />
                            </a>
                        </div>
                        <div className='w-8 h-auto p-1 mx-2 overflow-hidden'>
                            <a className='flex items-center justify-start' href='https://www.hackerrank.com/profile/devWithShreyash' target='_blank' rel='noreferrer'>
                                <img className='w-8 h-auto' src='https://res.cloudinary.com/dbmoqeaes/image/upload/v1719827674/hackerrank_bl1xwp.png' alt='HackerRank' />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='w-full float-left overflow-auto flex items-center justify-center mt-8'>
                    <hr className='w-[30%] h-[0.025rem] bg-white-input-light border-none' />
                    <GiAlienSkull className='text-2xl text-gray-light mx-1 animate-pulse z-10' />
                    <hr className='w-[30%] h-[0.025rem] bg-white-input-light border-none' />
                </div>
            </div>
        </div >
    )
}

export default MenuCard