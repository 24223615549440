import React from 'react';
import Lottie from 'react-lottie';
import Knowledge from '../Lottie/Knowledge.json';

const TechSkills = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Knowledge,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='w-full h-auto float-left overflow-auto px-4 lg:px-28'>

            {/* Page Title */}
            <div className='overflow-auto my-4'>
                <div className='text-4xl sm:text-3xl font-bold flex items-center justify-start'>
                    What I Know
                    <span className='w-min'>
                        <Lottie
                            options={defaultOptions}
                            height={50}
                            width={50}
                        />
                    </span>
                </div>
            </div>

            {/* Main Skills */}
            <div className='h-auto overflow-auto grid grid-cols-3 gap-4 sm:grid-cols-1 items-start justify-center'>


                {/* 1) Languages */}
                <div className='h-auto float-left overflow-auto border-[0.01rem] border-gray-light rounded-lg p-4 my-2'>
                    <p className='lg:text-lg text-base font-thin'><i className='text-blue'>programming language</i></p>
                    <div className='w-full h-auto mt-1 mb-1 break-word flex flex-wrap sm:text-sm'>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-yellow bg-white-input-light'>JavaScript</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-blue bg-white-input-light'>TypeScript</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-yellow bg-white-input-light'>Python</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-blue bg-white-input-light'>Golang</span>
                        {/* <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>Java</span> */}
                    </div>
                </div>

                {/* 2) Backend */}
                <div className='h-auto float-left overflow-auto border-[0.01rem] border-gray-light rounded-lg p-4 my-2'>
                    <p className='lg:text-lg text-base font-thin'><i className='text-blue'>backend</i></p>
                    <div className='w-full h-auto mt-1 mb-1 break-word flex flex-wrap sm:text-sm'>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-green bg-white-input-light'>Node Js</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-greee bg-white-input-light'>Nest Js</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-gray bg-white-input-light'>Express</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>Socket.io</span>
                    </div>
                </div>

                {/* 3) Frontend */}
                <div className='h-auto float-left overflow-auto border-[0.01rem] border-gray-light rounded-lg p-4 my-2'>
                    <p className='lg:text-lg text-base font-thin'><i className='text-blue'>frontend</i></p>
                    <div className='w-full h-auto mt-1 mb-1 break-word flex flex-wrap sm:text-sm'>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-blue bg-white-input-light'>React Js</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>React Native</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>Redux</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-blue bg-white-input-light'>Tailwind</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>HTML</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>CSS</span>
                    </div>
                </div>

                {/* 4) Database */}
                <div className='h-auto float-left overflow-auto border-[0.01rem] border-gray-light rounded-lg p-4 my-2'>
                    <p className='lg:text-lg text-base font-thin'><i className='text-blue'>database</i></p>
                    <div className='w-full h-auto mt-1 mb-1 break-word flex flex-wrap sm:text-sm'>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-green bg-white-input-light'>MongoDB</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-blue bg-white-input-light'>MySQL</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-red bg-white-input-light'>Redis</span>
                    </div>

                </div>

                {/* 5) Tools */}
                <div className='h-auto float-left overflow-auto border-[0.01rem] border-gray-light rounded-lg p-4 my-2'>
                    <p className='lg:text-lg text-base font-thin'><i className='text-blue'>tools</i></p>
                    <div className='w-full h-auto mt-1 mb-1 break-word flex flex-wrap  sm:text-sm'>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-orange bg-white-input-light'>Git</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>GitHub</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>AWS S3</span>
                        <span className='px-2 py-1 rounded-lg mt-2 mr-3 border border-black bg-white-input-light'>AWS EC2</span>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default TechSkills