import React from 'react';
import { CiLaptop } from "react-icons/ci";

const Experience = () => {

    const calculateTotalMonths = () => {
        const startDate = new Date('2021-08-01');
        const endDate = new Date();
        const totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
        return (totalMonths - 2) / 12
    };
    const totalMonths = calculateTotalMonths();


    return (
        <div className='w-full h-auto float-left overflow-auto py-2 my-8 px-4 lg:px-28'>
            <div className='px-4 md:px-8 lg:px-12 py-8 shadow hover:shadow-md hover:shadow-gray-light rounded-md border-[0.5px] border-green'>
                <h1 className='text-center font-bold text-green text-2xl md:text-3xl lg:text-4xl mb-4 flex items-center justify-center'><CiLaptop />&nbsp;Software Engineer</h1>
                <p className='text-center text-sm md:text-base lg:text-lg'>I am a dedicated software engineer with a deep-rooted passion for developing clean, efficient, and maintainable code. With a robust foundation in software development built through a B.Tech in Computer Science and {totalMonths.toFixed(1)}+ years of experience in the tech world, I thrive on solving real-world challenges. My expertise spans a wide range of technologies, including MERN stack, Python, Go, and microservices, allowing me to create scalable and innovative software solutions.</p>
            </div>
        </div>
    )
}

export default Experience