import { Toaster } from 'react-hot-toast';
import WelcomeWindow from './component/WelcomeWindow.jsx';

function App() {
  return (
    <div className='select-none cu'>
      <WelcomeWindow />
      <Toaster />
    </div>

  );
}

export default App;
